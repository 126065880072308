import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Stores from '../components/stores'
import styles from './student.module.css'

const numberOfGuides = 2

const StudentPage = ({ data }) => {
  const { formatMessage } = useIntl()

  const renderGuides = () => {
    const renderedGuides = []
    for (let i = 1; i <= numberOfGuides; i++) {
      const downloadablePath = formatMessage({
        id: `pages.student.sections.guides.guide${i}.file`,
      })
      const hasDownloadableFile = downloadablePath !== 'NULL'

      renderedGuides.push(
        <div
          key={i}
          className="mt-8 md:mt-0 w-full md:w-1/3 flex flex-col items-center"
        >
          <div className="w-48 md:w-full">
            <Img
              alt={`Download 0${i}`}
              fluid={data[`download0${i}`].childImageSharp.fluid}
            />
          </div>

          {hasDownloadableFile && (
            <a className="btn mt-8" href={downloadablePath} download>
              <FormattedMessage
                id={`pages.student.sections.guides.guide${i}.button`}
              />
            </a>
          )}

          {!hasDownloadableFile && (
            <span className="inline-block mt-8 text-yellow-light font-fredoka">
              <FormattedMessage id="general.comingSoon" />
            </span>
          )}
        </div>
      )
    }
    return renderedGuides
  }

  return (
    <Layout>
      <SEO
        lang={formatMessage({ id: 'lang' })}
        title={formatMessage({ id: 'pages.student.title' })}
        description={formatMessage({ id: 'pages.student.description' })}
      />

      <section className={styles.sectionLanding}>
        <div className="custom-container py-32 text-white">
          <div className="badge">
            <Img alt="Student badge" fluid={data.badge.childImageSharp.fluid} />
          </div>

          <h1 className="heading text-yellow-light uppercase mt-4">
            <FormattedMessage id="pages.student.title" />
          </h1>

          <p className="mt-8 text-yellow-light text-lg font-semibold">
            <FormattedMessage id="pages.student.sections.landing.title" />
          </p>

          <p className="mt-4">
            <FormattedMessage id="pages.student.sections.landing.p1" />
          </p>

          <p>
            <FormattedMessage id="pages.student.sections.landing.p2" />
          </p>

          <p className="mt-4">
            <FormattedMessage id="pages.student.sections.landing.download" />
          </p>

          <div className="mt-4 flex justify-center items-center">
            <Stores
              app="trainAtHome"
              appStoreClasses="w-32 mr-8"
              playStoreClasses="w-32"
            />
          </div>
        </div>

        <div className="custom-container custom-container--extralarge">
          <div className="relative w-full">
            <div className={styles.app}>
              <Img alt="App" fluid={data.app.childImageSharp.fluid} />
            </div>

            <div className={styles.chest}>
              <Img alt="Chest" fluid={data.chest.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-blue-darkest py-32">
        <div className="custom-container">
          <h2 className="heading text-yellow-light">
            <FormattedMessage id="pages.student.sections.guides.title" />
          </h2>

          <div className="w-4/5 md:w-2/3 mx-auto">
            <p className="text-white">
              <FormattedMessage id="pages.student.sections.guides.description" />
            </p>
          </div>

          <div className="mt-8 md:mt-16 flex justify-around flex-wrap">
            {renderGuides()}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    badge: file(relativePath: { eq: "badges/student-color.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    app: file(relativePath: { eq: "student/app.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chest: file(relativePath: { eq: "student/chest.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    download01: file(relativePath: { eq: "student/download-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 497) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    download02: file(relativePath: { eq: "student/download-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 497) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StudentPage
